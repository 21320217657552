import React from "react";
import { Box } from "@mui/material";
import FitbyteaiUsers from "../components/Fitbyteai/FitbyteaiUsers";

export const FitbyteaiUserPage = () => {
  return (
    <Box className="managepro6ix-layout-page">
      <FitbyteaiUsers />
    </Box>
  );
};

export default FitbyteaiUserPage;
